// Public: Sets the scrolling property of the body element.
export function setBodyScroll (value: boolean) {
  document.body.style.overflow = value ? 'visible' : 'hidden'
  document.documentElement.style.scrollbarGutter = document.body.style.scrollbarGutter = value ? 'auto' : 'stable'
}

type Maybe<T> = T | null | undefined

// Public: Scrolls the item into view.
export function scrollIntoView (element: Maybe<HTMLElement>, { animate, offset = 0 } = { animate: true }) {
  if (!element) return
  const scroller = getScrollParent(element)
  const scrollTop = scrollTopToDisplay(element, scroller) + offset
  animateScroll(scroller, { y: scrollTop, animate })
}

// Public: Scrolls to the top of the element (or it's first scrollable parent).
export function scrollToTop (element: Maybe<HTMLElement>, offset: number, { animate = true } = {}) {
  if (!element) return
  const scroller = getScrollParent(element)
  animateScroll(scroller, { y: offset, animate })
}

// Public: Scrolls to the bottom of the element (or it's first scrollable parent).
export function scrollToBottom (element: Maybe<HTMLElement>, { animate = true } = {}) {
  if (!element) return
  const scroller = getScrollParent(element)
  const scrollTop = scroller.scrollHeight - scroller.clientHeight
  animateScroll(scroller, { y: scrollTop, animate })
}

// Public: Scrolls to the element making it the top element visible in parent,
// or scrolls to bottom if there's no enough room.
export function scrollToElement (element: Maybe<HTMLElement>, { animate = true } = {}) {
  if (!element) return
  const scroller = getScrollParent(element)
  const scrollTop = element.offsetTop
  animateScroll(scroller, { y: scrollTop, animate })
}

// Public: Returns true if the parent has an Y Overflow and is showing a scrollbar
export function parentHasScrollbar (node: Node) {
  return getScrollParent(node) === node.parentElement
}

export interface ScrollOptions {
  animate?: boolean
  rate?: number
  linear?: boolean
  x?: number
  y?: number
}

// Internal: Simple function to animate the scroll motion.
export function animateScroll (scroller: HTMLElement, options: ScrollOptions) {
  const { x, y, rate = 20, animate = true, linear = false } = options

  const finalScrollPos = y ?? x
  const scrollPos = y !== undefined ? 'scrollTop' : x !== undefined ? 'scrollLeft' : undefined
  if (finalScrollPos === undefined || scrollPos === undefined) return

  if (!animate) {
    scroller[scrollPos] = finalScrollPos
    return
  }

  // Used to detect the progress of the scrolling animation.
  let lastScrollPos = scroller[scrollPos]
  let stopped = false

  const scroll = () => {
    if (stopped) return

    const currentScrollPos = scroller[scrollPos]

    // We want to abort the automatic scrolling if we detect a change in the
    // scroll that is external to this animation to prevent "loops".
    if (currentScrollPos !== lastScrollPos) return

    const floatScrollValue = (finalScrollPos - scroller[scrollPos]) / rate
    if (Math.abs(floatScrollValue) < 1) {
      scroller[scrollPos] = finalScrollPos
    }
    else {
      const scrollDistance = linear
        ? (finalScrollPos < currentScrollPos ? -rate : rate)
        : floatScrollValue > 0 ? Math.ceil(floatScrollValue) : Math.floor(floatScrollValue)

      scroller[scrollPos] = currentScrollPos + scrollDistance
      lastScrollPos = scroller[scrollPos]

      // If we haven't reached the final destination yet, animate another frame.
      if (scroller[scrollPos] !== currentScrollPos) requestAnimationFrame(scroll)
    }
  }
  scroll()

  return () => { stopped = true }
}

// Internal: Detects if an element is scrollable.
function isScrollable (node: Node): node is HTMLElement {
  const isElement = node instanceof HTMLElement
  const overflowY = isElement && getComputedStyle(node).overflowY
  return overflowY !== 'visible' && overflowY !== 'hidden'
}

// Internal: Returns the first ancestor that is scrollable.
function getScrollParent (node: Node | null): HTMLElement {
  if (!node) return document.documentElement
  if (isScrollable(node) && node.scrollHeight >= node.clientHeight) return node
  return getScrollParent(node.parentNode)
}

// Internal: Returns the scroll position necessary for the item to be visible.
function scrollTopToDisplay (element: HTMLElement, scroller: HTMLElement) {
  const distanceToTop = element.offsetTop - scroller.scrollTop
  const distanceToBottom = distanceToTop + element.offsetHeight - scroller.offsetHeight

  if (scroller === document.documentElement) return element.offsetTop
  if (distanceToTop < 0) return scroller.scrollTop + distanceToTop
  if (distanceToBottom > 0) return scroller.scrollTop + distanceToBottom
  return scroller.scrollTop
}
