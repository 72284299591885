import { union, isEqual, isNil } from 'lodash-es'
import { isPresent } from '@shared/object'

// Public: Returns the fields that changed from the original, making it easier
// to send only the data that changed.
export function changesFrom<T extends object> (original: T, modified: T) {
  const fields = union(Object.keys(original) as (keyof T)[], Object.keys(modified) as (keyof T)[])

  const changes: Partial<T> = {}

  // NOTE: Always keep the id to make it easier to update without changes.
  // @ts-ignore
  const id = original.id || modified.id
  // @ts-ignore
  changes.id = id

  fields.forEach((field) => {
    if (hasFieldChanged(original, modified, field)) {
      const newValue = modified[field]
      changes[field] = isNil(newValue) ? null as any : newValue
    }
  })

  return changes
}

// Public: Returns true if there are any fields changed.
export function hasChanged (value: object) {
  return Object.keys(value).filter(field => field !== 'id').length > 0
}

// Ignores empty values that were transformed to another empty
// value, for example: `undefined' to `null' or `undefined` to `empty`.
function hasFieldChanged<T> (original: T, modified: T, field: keyof T) {
  return !isEqual(original[field], modified[field])
    && (isPresent(original[field]) || isPresent(modified[field]))
}
