<!--
Provides functionality similar to Rails form helpers in Vue pages.

- Can provide data for children <GInput> components instead of using v-model
- Bubbles validation errors from inputs to prevent submitting the form
- Exposes errors returned by the server in an ErrorsSerializer format to <Input>
- Gathers validation errors from inputs and shares server errors
-->
<script setup lang="ts">import { ref as _ref, shallowRef as _shallowRef, computed as _computed } from 'vue';
const currentModel = __MACROS_toRef(__props, "model");

import { toRef as __MACROS_toRef } from "vue";
import { router } from '@shared/inertia'
import { get, set, unset } from 'lodash-es'
import { useInertiaListener } from '@shared/composables/inertia'
import { changesFrom } from '@shared/changes'
import { isEmpty, cloneDeep } from '@consumer/helpers/object'
import { scrollIntoView } from '@shared/scroll'
import type { Errors } from '@corp/serializers'
import { modalConfirm } from '@consumer/services/modals'
import { showError } from '@consumer/services/flash'
import { FORM_INJECTION_KEY } from '@consumer/services/form'
import { closeAllMessages } from '@shared/stores/flash'
import { useEventListener, useCurrentElement } from '@vueuse/core'
import { translateError, translateFieldErrorMessage, translateErrorMessage } from '@shared/validation'

export type SubmitFn = (model: any, event?: SubmitEvent) => Promise<any>

export interface FormProps {
  // The model that will be data-bound to the form.
  model?: any

  // The translation namespace for the form labels and placeholders.
  prefix?: string

  // The default size for all inputs in the form.
  size?: 'small' | 'large'

  // A Function to call when the Form is submitted.
  onSubmit: SubmitFn

  // The initial state of the model being modified.
  initialModel?: any

  // When enabled, the save button will be disabled until the form is modified.
  disableIfPristine?: boolean

  // Whether to require user's confirmation to leave the form.
  confirmLeave?: boolean

  // Whether to preserve data when an input or form is unregistered.
  keepFields?: boolean

  // Whether to submit only the fields that changed instead of the entire model.
  trackChanges?: boolean

  // Whether to update the model with the submit response.
  noUpdateModel?: boolean

  // Offset used to scroll to errors
  errorOffset?: number

  // Whether or not to proceed even with errors
  ignoreErrors?: boolean
}

withDefaults(defineProps<FormProps>(), { errorOffset: 0, })

const emit = defineEmits<{(e: 'input:valid', field: string): void
  (e: 'input:errors', field: string, errors: string[]): void
  (e: 'model:change', model: Record<string, any>, field: string): void
}>()

// eslint-disable-next-line vue/no-dupe-keys
let model = _ref<any>()
let originalModel: any
watchEffect(() => {
  model.value = __props.model ?? cloneDeep(toRaw(__props.initialModel) ?? {})
  if (__props.trackChanges) originalModel = cloneDeep(toRaw(model.value))
})

// True if no inputs have changed since instantiation.
// FALSE DOES NOT NECESSARILY MEAN THE VALUES HAVE CHANGED.
let pristine = _ref(true)

// Errors are not displayed until the form is submitted.
let submitted = _ref(false)

// Allows other components to check on submission progress.
let submitting = _ref(false)

// Errors received from the inputs.
const inputErrors = _ref<Record<string, any>>({})

// Errors received from the server.
let serverErrors = _ref<Errors['messages']>({})

// Errors received from the server that don't match any visible input.
let formServerErrors = _shallowRef<string[]>([])

// Returns true if no inputs in the form have validation errors.
const isValid = _computed(() => isEmpty(inputErrors.value) && isEmpty(serverErrors.value))

const statusCss = _computed(() => [
  submitted.value && 'submitted',
  submitting.value && 'submitting',
  __props.disableIfPristine && pristine.value && 'pristine',
  isValid.value ? 'valid' : 'invalid',
])

// Display server errors with a flash message, unless there's a slot for server errors
const slots = useSlots()
if (!slots.serverErrors) {
  watch((formServerErrors), (errors, previousErrors) => {
    errors.forEach((error) => {
      if (!previousErrors.includes(error))
        showError({ message: error }, { duration: 'permanent' })
    })
  })
}

useEventListener('beforeunload', (event) => {
  if (shouldConfirmLeave()) {
    event.preventDefault()
    event.returnValue = true
  }
})

const cleanInertiaListener = useInertiaListener('before', ({ visit }, event?: any) => {
  if (shouldConfirmLeave()) {
    event.preventDefault()
    modalConfirm({
      confirmLabel: 'Exit Without Saving',
      content: 'You have unsaved changes. Are you sure you want to exit?',
      onConfirm: () => {
        cleanInertiaListener()
        router.visit(visit.url, visit.data as any)
      },
    })
  }
})

// If the user attempts to navigate away when the form has pending changes, we
// should display a dialog asking them to confirm.
function shouldConfirmLeave () {
  return __props.confirmLeave && !submitting.value && !pristine.value
}

// If the form is valid, it will invoke onSubmit, and observe the returned
// promise to display a loading state, or error messages if it's rejected.
async function onFormSubmit (event?: SubmitEvent): Promise<boolean> {
  submitted.value = true

  if (isValid.value || __props.ignoreErrors)
    return submitForm(event)

  scrollToFirstError()
  return false
}

const formEl = (useCurrentElement<HTMLFormElement>())

// Internal: Scrolls to the first input that has an error.
function scrollToFirstError () {
  nextTick(() => {
    const inputWithError = formEl.value.querySelector<HTMLElement>('.form-input.invalid')
    scrollIntoView(inputWithError, { animate: true, offset: __props.errorOffset })
  })
}

// Calls onSubmit and handles the returned promise to display a loading state,
// and any errors returned from the server.
async function submitForm (event?: SubmitEvent) {
  submitting.value = true
  formServerErrors.value = []
  closeAllMessages()

  const payload = __props.trackChanges ? changesFrom(originalModel, model.value) : model.value
  const submittedChanges = cloneDeep(toRaw(model.value))

  try {
    const result = await __props.onSubmit(payload, event)
    if (result && !__props.noUpdateModel) {
      Object.assign(model.value, result)
      originalModel = submittedChanges
    }
    pristine.value = true
    submitted.value = false

    return true
  }
  catch (error: any) {
    const response = error?.response
    if (response?.data?.errors)
      onServerErrors(response.data.errors)
    else if (error?.errorMessages)
      onServerErrors(error.errorMessages)
    else
      formServerErrors.value = translateError(error)

    return false
  }
  finally {
    submitting.value = false
  }
}

// Internal: Processes server errors in order to display them.
function onServerErrors (errors: Errors) {
  const errorsWithInput: Errors['messages'] = {}
  const errorsWithoutInput: Errors['fullMessages'] = []
  Object.entries(errors.messages).forEach(([field, messages]) => {
    if (formFields.has(field))
      errorsWithInput[field] = messages.map(translateErrorMessage)
    else
      errorsWithoutInput.push(...messages.map(m => translateFieldErrorMessage(field, m)))
  })
  serverErrors.value = errorsWithInput
  formServerErrors.value = errorsWithoutInput
  scrollToFirstError()
}

// Internal: Cleans server errors, either received from the parent Form or
// obtained while submitting the current Form
function cleanServerErrorsFor (fieldProp: string) {
  if (serverErrors.value[fieldProp]) delete serverErrors.value[fieldProp]
}

const formFields = new Set<string>()

defineExpose({
  // Public: Expose programmatic access to the form state.
  isValid: (isValid),

  // Public: Expose programmatic access to submit the form.
  submit: onFormSubmit,

  // Public: Expose programmatic access to the form state.
  submitting: (submitting),
})

provide(FORM_INJECTION_KEY, {
  size: __props.size,
  isValid: (isValid),
  disableSubmit: computed(() => Boolean(__props.disableIfPristine && pristine.value)),
  submitting: (submitting),
  displayErrors: (submitted),
  translationOptions: { prefix: __props.prefix },
  submit: onFormSubmit,

  get originalModel () {
    return originalModel
  },

  registerInput ({ field, formData }) {
    formFields.add(field)
    formData.value = computed(() => get(model.value, field))
    formData.serverErrors = computed(() => get(serverErrors.value, field)) as any
  },

  unregisterInput (field) {
    formFields.delete(field)
    this.onInputValid(field)
    if (!__props.keepFields) unset(model.value, field)
  },

  // NestedForm: Remove the corresponding data when a nested form is removed.
  unregisterNestedForm (modelName) {
    if (!__props.keepFields) unset(model.value, modelName)
  },

  onInputValid (field) {
    emit('input:valid', field)
    delete inputErrors.value[field]
    cleanServerErrorsFor(field)
  },

  // FormInput: Gather errors from the validations run in the input.
  onInputErrors (field, errors) {
    emit('input:errors', field, errors)
    inputErrors.value[field] = errors
  },

  // Updates the model value, which will cause an update on the input "value" prop
  // and clear any previous server errors.
  onInputValueChange (field, value) {
    pristine.value = false
    set(model.value, field, value)
    cleanServerErrorsFor(field)
    emit('model:change', model.value, field)
  },
})
</script>

<template>
  <form
    :class="statusCss"
    :disabled="submitting"
    novalidate
    autocomplete="off"
    @submit.prevent="onFormSubmit($event as any)"
    @reset.prevent
  >
    <slot/>
    <slot name="serverErrors" :errors="formServerErrors"/>
    <slot name="footer"/>
  </form>
</template>

<style scoped lang="scss">
form {
  --form-input-spacing: 1.5rem;
}

form > {
  :deep(.form-input),
  :deep(.form-input-group .form-input),
  :deep(.form-grid) {
    margin-bottom: var(--form-input-spacing);
  }
}
</style>
